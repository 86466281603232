import { PerpsProvider } from '@kwenta/sdk/types'
import { useCallback } from 'react'

import { MAX_TOTAL_TRADES, POLL_FREQUENCY_INTERVALS } from 'constants/defaults'
import { useAppSelector, useFetchAction, usePollAction } from 'state/hooks'
import { fetchPreviousDayPrices } from 'state/prices/actions'
import {
	fetchAllReferralData,
	fetchBoostNftForAccount,
	fetchBoostNftMinted,
	fetchReferralNftForAccount,
} from 'state/referrals/action'
import {
	fetchClaimableRewards,
	fetchFeesPaid,
	fetchReferralFeesPaid,
	fetchStakeMigrateData,
} from 'state/staking/actions'
import { selectEpochPeriod, selectSelectedEpoch } from 'state/staking/selectors'
import {
	selectIsWatcherMode,
	selectOneClickTradingWalletAddress,
	selectWallet,
} from 'state/wallet/selectors'

import { DEFAULT_PERPS_PROVIDER } from 'constants/futures'
import { selectAppIsFocused } from 'state/app/selectors'
import {
	fetchAbstractionDelegateStatus,
	fetchAccountData,
	fetchDashboardChartData,
	fetchDelegatesForAccount,
	fetchFuturesPositionHistory,
	fetchMarketData,
	fetchOrderHistory,
	fetchPerpsAccounts,
	fetchSubAccountsForAccount,
	fetchTradeHistory,
} from '../actions'
import {
	selectMarketsLength,
	selectPerpsProvider,
	selectProviderNetwork,
} from '../common/selectors'
import { fetchFuturesFees, fetchFuturesFeesForAccount } from '../isolatedMargin/actions'
import { selectSnxV2Account } from '../isolatedMargin/selectors'
import { selectAccountContext } from '../selectors'
import { useFetchAccountData } from './useFetchAccountData'
import { useFetchMarketTrades } from './useFetchMarketTrades'
import { useFetchTabData } from './useFetchTabData'
import { useFetchV3MarketLiquidations } from './useFetchV3MarketLiquidations'

// TODO: Optimise polling and queries
export const usePollMarketFuturesData = () => {
	const wallet = useAppSelector(selectWallet)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const smartMarginAddress = useAppSelector(selectSnxV2Account)
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const focused = useAppSelector(selectAppIsFocused)
	const focus = focused ? 'active' : 'inactive'

	const lowFrequencyInterval = POLL_FREQUENCY_INTERVALS[focus].low

	useFetchMarketTrades()
	useFetchV3MarketLiquidations()
	useFetchTabData()
	useFetchAccountData()

	useFetchAction(fetchBoostNftMinted, {
		dependencies: [wallet],
		disabled: isWatcherMode,
		name: 'fetchBoostNftMinted',
	})

	useFetchAction(fetchBoostNftForAccount, {
		dependencies: [wallet],
		disabled: isWatcherMode,
		name: 'fetchBoostNftForAccount',
	})

	const fetchAccounts = useCallback(
		() => fetchPerpsAccounts({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [wallet, selectedPerpsProvider],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	const fetchDelegates = useCallback(
		() => fetchDelegatesForAccount([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchDelegates, {
		dependencies: [wallet, smartMarginAddress, selectedPerpsProvider],
		name: 'fetchDelegates',
	})

	const fetchSubAccounts = useCallback(
		() => fetchSubAccountsForAccount([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchSubAccounts, {
		dependencies: [wallet, smartMarginAddress, selectedPerpsProvider],
		name: 'fetchSubAccounts',
	})

	const fetchMarketDataAction = useCallback(
		() => fetchMarketData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchMarketData', fetchMarketDataAction, {
		dependencies: [selectedPerpsProvider],
		intervalTime: lowFrequencyInterval,
	})

	const fetchAllMarketDataAction = useCallback(
		() => fetchMarketData([PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE]),
		[]
	)

	useFetchAction(fetchAllMarketDataAction, {
		dependencies: [],
		name: 'fetchAllMarketDataAction',
	})
}

export const usePollDashboardFuturesData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const marketsLength = useAppSelector(selectMarketsLength)
	const wallet = useAppSelector(selectWallet)
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const { accountId } = useAppSelector(selectAccountContext)
	const focused = useAppSelector(selectAppIsFocused)

	const focus = focused ? 'active' : 'inactive'

	const highFrequencyInterval = POLL_FREQUENCY_INTERVALS[focus].high
	const midFrequencyInterval = POLL_FREQUENCY_INTERVALS[focus].mid
	const lowFrequencyInterval = POLL_FREQUENCY_INTERVALS[focus].low

	const fetchAccounts = useCallback(
		() => fetchPerpsAccounts({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [networkId, wallet, selectedPerpsProvider],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	const fetchMarketDataAction = useCallback(
		() => fetchMarketData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchMarketData', fetchMarketDataAction, {
		dependencies: [selectedPerpsProvider],
		intervalTime: lowFrequencyInterval,
	})

	const fetchPosHistory = useCallback(
		() => fetchFuturesPositionHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchFuturesPositionHistory', fetchPosHistory, {
		intervalTime: highFrequencyInterval,
		dependencies: [wallet, accountId],
		disabled: !wallet,
	})

	const fetchAccountDataAction = useCallback(
		() => fetchAccountData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchAccountData', fetchAccountDataAction, {
		intervalTime: midFrequencyInterval,
		dependencies: [marketsLength, accountId],
		disabled: !marketsLength,
	})

	const fetchTradeHistoryAction = useCallback(
		() => fetchTradeHistory({ providers: [selectedPerpsProvider], pageSize: MAX_TOTAL_TRADES }),
		[selectedPerpsProvider]
	)

	usePollAction('fetchTradeHistory', fetchTradeHistoryAction, {
		dependencies: [networkId, wallet, selectedPerpsProvider, accountId],
		intervalTime: midFrequencyInterval,
		disabled: !wallet,
	})

	usePollAction('fetchDashboardChartData', fetchDashboardChartData, {
		dependencies: [accountId, selectedPerpsProvider],
		intervalTime: midFrequencyInterval,
	})

	const fetchOrderHistoryAction = useCallback(
		() => fetchOrderHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchOrderHistory', fetchOrderHistoryAction, {
		dependencies: [accountId, selectedPerpsProvider],
		intervalTime: midFrequencyInterval,
		disabled: !wallet,
	})
}

export const useFetchRewardsData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const wallet = useAppSelector(selectWallet)
	const { start, end } = useAppSelector(selectSelectedEpoch)
	const fetchFuturesFeesAction = useCallback(() => fetchFuturesFees([PerpsProvider.SNX_V2_OP]), [])

	const fetchFuturesFeesForAccountAction = useCallback(
		() => fetchFuturesFeesForAccount([PerpsProvider.SNX_V2_OP]),
		[]
	)

	const fetchAccounts = useCallback(
		() =>
			fetchPerpsAccounts({
				providers: [PerpsProvider.SNX_V2_OP],
			}),
		[]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [networkId, wallet],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	useFetchAction(fetchFeesPaid, {
		dependencies: [networkId, wallet],
		name: 'fetchFeesPaid',
	})

	useFetchAction(fetchReferralFeesPaid, {
		dependencies: [networkId, wallet],
		name: 'fetchReferralFeesPaid',
	})

	useFetchAction(fetchClaimableRewards, {
		dependencies: [networkId, wallet],
		name: 'fetchClaimableRewards',
	})

	useFetchAction(fetchFuturesFeesAction, {
		dependencies: [networkId, start, end],
		name: 'fetchFuturesFees',
	})

	useFetchAction(fetchFuturesFeesForAccountAction, {
		dependencies: [networkId, wallet, start, end],
		disabled: !wallet,
		name: 'fetchFuturesFeesForAccount',
	})
}

export const useFetchStakeMigrateData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const wallet = useAppSelector(selectWallet)
	const { start, end } = useAppSelector(selectSelectedEpoch)
	const fetchFuturesFeesSnxV2 = useCallback(() => fetchFuturesFees([PerpsProvider.SNX_V2_OP]), [])
	const fetchFuturesFeesForAccountSnxV2 = useCallback(
		() => fetchFuturesFeesForAccount([PerpsProvider.SNX_V2_OP]),
		[]
	)
	useFetchAction(fetchStakeMigrateData, {
		dependencies: [networkId, wallet],
		name: 'fetchStakeMigrateData',
	})

	useFetchAction(fetchFuturesFeesSnxV2, {
		dependencies: [networkId, start, end],
		name: 'fetchFuturesFeesSnxV2',
	})

	useFetchAction(fetchFuturesFeesForAccountSnxV2, {
		dependencies: [networkId, wallet, start, end],
		disabled: !wallet,
		name: 'fetchFuturesFeesForAccountSnxV2',
	})
}

export const useFetchReferralData = () => {
	const wallet = useAppSelector(selectWallet)
	const period = useAppSelector(selectEpochPeriod)

	useFetchAction(fetchAllReferralData, {
		name: 'fetchAllReferralData',
		dependencies: [wallet, period],
		disabled: !wallet || !period,
	})
}

export const useFetchAbstractionData = () => {
	const oneClickTradingWalletAddress = useAppSelector(selectOneClickTradingWalletAddress)
	const { network, accountId } = useAppSelector(selectAccountContext)

	useFetchAction(fetchAbstractionDelegateStatus, {
		dependencies: [accountId, oneClickTradingWalletAddress, network],
		disabled: !accountId,
		name: 'fetchAbstractionDelegateStatus',
	})
}

export const useFetchLandingPageMarketData = () => {
	const fetchMarketDataAction = useCallback(() => fetchMarketData([DEFAULT_PERPS_PROVIDER]), [])

	useFetchAction(fetchMarketDataAction, {
		dependencies: [],
		name: 'fetchMarketData',
	})

	useFetchAction(fetchPreviousDayPrices, {
		dependencies: [],
		name: 'fetchPreviousDayPrices',
	})
}

export const useFetchAccountPageData = () => {
	const { wallet, network, accountId } = useAppSelector(selectAccountContext)

	const fetchAccounts = useCallback(
		() =>
			fetchPerpsAccounts({
				providers: [PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE],
			}),
		[]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [network, wallet],
		name: 'fetchAccounts',
	})

	const fetchAccountDataAction = useCallback(
		() => fetchAccountData([PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE]),
		[]
	)

	usePollAction('fetchAccountData', fetchAccountDataAction, {
		intervalTime: 30000,
		dependencies: [accountId],
	})

	useFetchAction(fetchReferralNftForAccount, {
		dependencies: [network, wallet],
		name: 'fetchReferralNftForAccount',
	})

	const fetchDelegates = useCallback(
		() => fetchDelegatesForAccount([PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE]),
		[]
	)

	useFetchAction(fetchDelegates, {
		dependencies: [wallet],
		name: 'fetchDelegates',
	})

	const fetchSubAccounts = useCallback(
		() => fetchSubAccountsForAccount([PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE]),
		[]
	)

	useFetchAction(fetchSubAccounts, {
		dependencies: [wallet],
		name: 'fetchSubAccounts',
	})
}
